import axios from 'axios'
import store from '../store'
import {COMPANY_MODULE, PUBLIC_MODULE} from './_prefix'
import router from '../router'

export const getFundamentalsInfo = (code, year, month) => {
  return axios.get(`${COMPANY_MODULE}/getFundamentals`, {
    params: {
      company_code: code,
      year: year,
      month: month
    },
    headers: {
      Authorization: store.state.token
    }
  }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getAllCompanies = () => {
  return axios.get(`${PUBLIC_MODULE}/getAllCompanies`, {
    headers: {
      Authorization: store.state.token
    }
  }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getESGRatings = (code, year, month) => {
  return axios.get(`${COMPANY_MODULE}/getESGRatings`,
    {
      params: {
        company_code: code,
        year: year,
        month: month
      },
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getStructuredData = (code, year, month) => {
  return axios.get(`${COMPANY_MODULE}/getStructuredData`,
    {
      params: {
        company_code: code,
        year: year,
        month: month
      },
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getBasicInfo = (code, date) => {
  return axios.get(`${COMPANY_MODULE}/getStockValue`,
    {
      params: {
        company_code: code,
        date: date
      },
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getTextData = (code, currentPage, pageSize, filterList, order) => {
  return axios.post(`${COMPANY_MODULE}/getESGText`,
    {
      company_code: code,
      currentPage: currentPage,
      pageSize: pageSize,
      filterList: filterList,
      orderBy: order,
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push("/login");
      }
    }
  });
}

export const getDate = ()=>{
  return  axios.get(`${COMPANY_MODULE}/getESGUpdateTime`,
  {
    headers: {
      Authorization: store.state.token
    }
  }).then(res=>{
    return res.data
  }).catch(function(error){
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push("/login");
      }
    }
  })
}
