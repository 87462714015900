export function setToken(token) {
  localStorage.setItem('token', token)
}

export function getToken() {
  return localStorage.getItem('token')
}

export function formatYYYYMM (date) {
  if (date !== null) {
    let month = date.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    const year = date.getFullYear()
    return year + '-' + month
  }
  return null
}

export const latestYear = '2022'
export const latestMonth = '05'

export function getUpNum (v, step) {
  const mod = v % step
  return mod ? v - mod + step : v
}

export function getDownNum (v, step) {
  const mod = v % step
  return mod ? v - mod : v
}

export function getFixedN (x, n) {
  x = x.toFixed(n)
  while ((x.length - x.indexOf('.') - 1) < n) {
    x += '0'
  }
  return x
}
