import axios from 'axios'
import store from '../store'
import {COMBINATION_MODULE} from '@/api/_prefix'
import router from '../router'

export const getCombInfo = userId => {
  return axios.get(`${COMBINATION_MODULE}/get`,
    {
      params: {
        userid: userId
      },
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const updateCombInfo = (userId, combInfo) => {
  return axios.post(`${COMBINATION_MODULE}/update`,
    {
      userid: userId,
      info: combInfo
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const addNewComb = (userId, combInfo, combName) => {
  return axios.post(`${COMBINATION_MODULE}/add`,
    {
      userid: userId,
      list: combInfo,
      combname: combName
    },
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const deleteComb = (userId, combName) => {
  return axios.get(`${COMBINATION_MODULE}/delete`,

    {
      params: {
        userid: userId,
        combname: combName
      },
      headers: {
        Authorization: store.state.token
      }
    }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const calcv1 = (userId, combId) => {
  return axios.post(`${COMBINATION_MODULE}/calcv1`,
    {
      userid: userId,
      portfolioid: combId
    },
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const calcv2 = (portfolio) => {
  return axios.post(`${COMBINATION_MODULE}/calcv2`,
    {
      portfolio: portfolio
    },
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const filterStock = (pointers, industries) => {
  return axios.post(`${COMBINATION_MODULE}/filter`,
    {
      pointerChecked: pointers,
      industryChecked: industries
    },
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push("/login");
      }
    }
  });
}

export const filterByML = () => {
  return axios.post(`${COMBINATION_MODULE}/ML`, {},
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push("/login");
      }
    }
  });
}

export const filterByTail = () => {
  return axios.post(`${COMBINATION_MODULE}/tail-culling`, {},
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push("/login");
      }
    }
  });
}
